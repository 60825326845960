





























import Vue from "vue";

export default Vue.extend({
  name: "Unauthorized",

  props: {
    id: {
      type: String,
      default: "about",
    },
  },

  provide: {
    heading: { align: "center" },
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px - 80px)`;
    },
  },
});
